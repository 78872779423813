.artist-item .thumbex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artist-img {
  cursor: pointer;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle-custom {
  cursor: pointer;
  text-align: center;
}

.dropdown-menu-custom {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown-menu-custom a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu-custom a:hover {
  background-color: #000;
}
